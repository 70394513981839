import React from "react";
import { graphql, PageProps } from "gatsby";

import { ErrorMsg, Header, Hero, SEO, Blocks } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import { Query } from "@graphql-types";
import { VillagePageContext } from "@state/types";
import CtaBlock from "@components/ctaBlock";
import PropertyPreviewGrid from "@components/property/previewGrid";
interface Props extends PageProps {
  data: Query;
  pageContext: VillagePageContext;
}

export default function VillageHomeOptionsTemplate({
  data: { sanityVillage, sanityHeader },
  pageContext,
}: Props) {
  if (sanityVillage == null)
    return <ErrorMsg data={sanityVillage} msg="Error fetching data for page" />;

  const {
    _type,
    slug,
    villageColour,
    homeOptionsSeo,
    homeOptionsHero,
    homeOptionsBlocks,
    homeOptionsOverview,
    listings,
  } = sanityVillage;

  // hooks
  usePageMeta(villageColour, _type, pageContext);
  const seo = homeOptionsSeo;
  const hero = homeOptionsHero;
  const blocks = homeOptionsBlocks;
  const homeListings = listings;

  return (
    <div>
      <SEO
        seoData={seo}
        slug={slug?.current as string}
        overwriteImage={hero?.backgroundImages && hero?.backgroundImages[0]?.asset?.url}
      />
      <Header data={sanityHeader} village={sanityVillage} />
      <Hero data={hero} />
      {homeOptionsOverview && <CtaBlock noBackgroundColor data={homeOptionsOverview} />}
      {homeListings && <PropertyPreviewGrid data={homeListings} />}
      <Blocks data={blocks} />
    </div>
  );
}

export const query = graphql`
  query villageHomeOptionQuery($slug: String) {
    sanityHeader {
      ...sanityHeader
    }
    sanityVillage(slug: { current: { eq: $slug } }) {
      ...sanityVillageHomeOptionsLanding
    }
  }
`;
